<template>
  <div class="factory-index p-3">
    <div class="e-top">
        <search/>
        <a class="btn btn-success btn-sm btn-lg w-100 mt-1 " href="/company/add">创建企业</a>
    </div>
    <div
      class="factory-index-box flex-column bg-white panel-body rounded-lg mb-2 mt-2"
      v-for="(value, code) in data"
      :key="code"
    >
      <button type="button" class="btn-close close" @click="deleteCom(value.id)"></button>

      <div
        class="
          col
          px-2
          py-2
          d-flex
          justify-content-start
          align-content-center align-items-center
        "
        style="width: 85%"
      >
        <div class="">
          <img
            class="align-middle"
            :src="value.logo"
            width="55"
            height="55"
            alt=""
          />
        </div>

        <div class="p-2 d-inline-block w-9">
          <router-link :to="`/company/info?id=${value.id}`">
            <h6 :id="`name${value.id}`" class="text-truncate w-100">{{ value.name }}</h6>
            <p class="mb-0 text-truncate w-100 fs-9">
              {{ NATURE[value.nature] }} | {{ COMPANY_TYPE[value.type] }} | <span v-for="(value, code) in value.welfare" :key="code">{{ WELFARE[value] }} | </span>
            </p>
          </router-link>
        </div>
      </div>
      <div class="col py-1">
        <div class="text-truncate px-2">
          <p class="text-muted m-0 py-0">
            <i class="fa"></i> 创建于: {{ value.create_time }}
          </p>
          <p class="text-muted m-0 py-0">
            <i class="fa"></i> 地 址: {{ value.address }}
          </p>
        </div>
        <div
          class="
            bg-light
            mt-2
            text-truncate
            rounded-lg
            d-flex
            justify-content-center
            align-content-center
          "
        >
          <a :href="'/tool/applys/info?company_id=' + value.id" class="mx-1" v-if="showStaff">应聘者({{ applyCount }})</a>
          <a href="#" class="mx-1" v-if="showSupplier">供应商({{ supplierCount }})</a>
          <a href="#" class="mx-1" v-if="showInFactory">驻 厂({{ inFactoryCount }})</a>
          <a href="/jobs" class="mx-1">职 位({{ jobCount }})</a>
          <a :href="`/company/edit?id=${value.id}`" class="mx-1">修 改</a>
        </div>
      </div>
    </div>

    <div class="e-countent-list-title">
      您还没有创建企业，请点击下面去创建吧．
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue"
import Axios from 'axios'
import { NATURE, COMPANY_TYPE, WELFARE } from "@/lib/data"
export default {
  name: "company",
  setup() {
    const data = ref([]);
    const jobCount = ref(0)
    const applyCount = ref(0)
    const inFactoryCount = ref(0)
    const supplierCount = ref(0)
    const showStaff = ref(true)
    const showSupplier = ref(true)
    const showInFactory = ref(true)
    
    const a = 10
    for(var i=0; i<a; i++){
      data.value.push(
        {
          id: 100 + i,
          name: "一二一通信苏州有限公司" + i,
          logo: "/imgs/logo.jpg",
          imags: ["/imgs/h01.jpg", "/imgs/h02.jpg", "/imgs/h03.jpg"],
          address: "苏州工业园区1111",
          contact: "simon",
          contact_tel: "180180180180",
          creater: { id: 1000, name: "jcccc" },
          create_time: "2022-01-01",
          nature: 0,
          type: 0,
          welfare:[1,2,3,4]
        }
      )
    }
//获取数据
    function getData() {
      Axios.get('http://localhost:5000/companys').then((response) => {
        if(response.data.code == 0) {
          data.value = response.data.data
        }
      })
    }
//获取企业的职位数量
    function getJobCounts() {
      Axios.get('http://localhost:5000/jobs').then((response)=>{
        if(response.data.code == 0) {
          // jobCount.value = response.data.data
        }
      })
    }
//获取投递者数量
    function getApplyCounts() {
      Axios.get('http://localhost:5000/jobs').then((response)=>{
        if(response.data.code == 0) {
          // applyCount.value = response.data.data
        }
      })
    }
//获取驻厂数量
    function getInFactoryCounts() {
      Axios.get('http://localhost:5000/jobs').then((response)=>{
        if(response.data.code == 0) {
          // inFactoryCount.value = response.data.data
        }
      })
    }
//获取供应商数量
    function getSupplierCounts() {
      Axios.get('http://localhost:5000/jobs').then((response)=>{
        if(response.data.code == 0) {
          // supplierCount.value = response.data.data
        }
      })
    }
    onMounted(()=>{
      getData()
      getJobCounts()
      getApplyCounts()
      getInFactoryCounts()
      getSupplierCounts()
    })

    function deleteCom(id) {
        var a = document.getElementById('name' + id).innerHTML
        if(confirm('你确定要删除' + a + '吗？')) {
          Axios.delete('http://localhost:5000/companys/' + id).then((Response) => {
             if(Response.data.code == 0) {
               for (const index in data.value) {
                 if(data.value[index].id == id) {
                  data.value.splice(index, 1)
                 }
               }
               alert('删除成功')
             }
          })
        }
    }
    return {
      showInFactory,
      showSupplier,
      showStaff,
        WELFARE,
        COMPANY_TYPE,
        NATURE,
        data,
        jobCount,
        applyCount,
        inFactoryCount,
        supplierCount,
        deleteCom
    };
  },
};
</script>

<style scoped>
.close {
  position: absolute;
  right: 25px;
  margin-top: 10px;
}
</style>>